import {
	Link,
	isRouteErrorResponse,
	useParams,
	useRouteError,
} from '@remix-run/react';
import { type ErrorResponse } from '@remix-run/router';
import { Button, type ButtonProps } from './ui/button.tsx';
import { useAppMode } from './dashboard/app-mode-provider.tsx';

type StatusHandler = (info: {
	error: ErrorResponse;
	params: Record<string, string | undefined>;
}) => JSX.Element | null;

type ErrorBoundaryProps = {
	defaultStatusHandler?: StatusHandler;
	statusHandlers?: Record<number, StatusHandler>;
	unexpectedErrorHandler?: (error: unknown) => JSX.Element | null;
	defaultHandler?: (error: unknown) => JSX.Element | null;
	noWrapper?: boolean;
	log?: boolean;
};

export function GeneralErrorBoundary({
	defaultHandler = () => (
		<>
			<ErrorHeader
				title="Oops!"
				message="We couldn't find the page you were looking for."
			/>
			<Button variant="ghost-outline" asChild>
				<Link to="/" className="text-center text-body-md">
					Back to home
				</Link>
			</Button>
		</>
	),
	defaultStatusHandler,
	statusHandlers,
	unexpectedErrorHandler,
	noWrapper,
	log,
}: ErrorBoundaryProps) {
	const error = useRouteError();
	const params = useParams();

	if (typeof document !== 'undefined' && log !== false) {
		console.error(error);
	}

	const errorComponent = isRouteErrorResponse(error)
		? (
				statusHandlers?.[error.status] ??
				defaultStatusHandler ??
				defaultHandler
			)({
				error,
				params,
			})
		: (unexpectedErrorHandler ?? defaultHandler)(error);

	if (noWrapper) {
		return errorComponent;
	}

	return (
		<div className="container flex flex-col items-center justify-center gap-6 p-4 text-h2 xs:p-6 sm:p-10 md:p-20">
			{errorComponent}
		</div>
	);
}

export function NotFoundErrorBoundary() {
	const mode = useAppMode();
	return (
		<GeneralErrorBoundary
			defaultHandler={() => (
				<>
					<ErrorHeader
						title="Oops!"
						message="We couldn't find the page you were looking for."
					/>
					<ErrorActions
						left={{
							to: '/',
							text: 'Back to landing',
							variant: 'ghost-outline',
						}}
						right={{
							to: mode === 'host' ? '/app/host' : '/app',
							text: 'Back to app',
							variant: 'gradient',
						}}
					/>
				</>
			)}
		/>
	);
}

export function AppErrorBoundary() {
	const mode = useAppMode();
	return (
		<GeneralErrorBoundary
			defaultHandler={() => (
				<>
					<ErrorHeader title="Oops!" message="Something went wrong..." />
					<ErrorActions
						left={{
							to: '/',
							text: 'Back to landing',
							variant: 'ghost-outline',
						}}
						right={{
							to: mode === 'host' ? '/app/host' : '/app',
							text: 'Back to app',
							variant: 'gradient',
						}}
					/>
				</>
			)}
		/>
	);
}

type ErrorHeaderProps = {
	title: string;
	message: string | React.ReactNode;
};
export function ErrorHeader(props: ErrorHeaderProps) {
	const { title, message } = props;

	return (
		<div className="flex flex-col items-center gap-3">
			<h1>{title}</h1>
			{typeof message === 'string' ? (
				<p className="text-center text-body-md">{message}</p>
			) : (
				message
			)}
		</div>
	);
}

type ErrorActionsProps = {
	left:
		| { to: string; text: string; variant?: ButtonProps['variant'] }
		| React.ReactNode;
	right:
		| { to: string; text: string; variant?: ButtonProps['variant'] }
		| React.ReactNode;
};
export function ErrorActions(props: ErrorActionsProps) {
	const { left, right } = props;

	return (
		<div className="flex flex-col items-center gap-1 xs:flex-row xs:gap-3">
			{typeof left === 'object' && left && 'to' in left ? (
				<Button variant={left.variant ?? 'ghost-outline'} asChild>
					<Link to={left.to} className="text-center text-body-md">
						{left.text}
					</Link>
				</Button>
			) : (
				left
			)}
			<p className="text-body-md">or</p>
			{typeof right === 'object' && right && 'to' in right ? (
				<Button variant={right.variant ?? 'gradient'} asChild>
					<Link to={right.to} className="text-center text-body-md">
						{right.text}
					</Link>
				</Button>
			) : (
				right
			)}
		</div>
	);
}
